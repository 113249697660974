import {
  ActivitySummaryResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import {
  Segment,
  toFilterSearchParams,
  useFetchSegments,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { useUsers } from '@capturi/stores'
import {
  ContentPlaceholder,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@capturi/ui-components'
import { Trans, t } from '@lingui/macro'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import React from 'react'
import formatSeconds from 'utils/formatSeconds'

import NoAccessibleTrackers from '../shared/components/NoAccessibleTrackers'
import TeamFilterRequiredMessage from '../shared/components/TeamFilterRequiredMessage'
import { Event, logEvent } from '../shared/events'
import { TeamSegments, UserSegments } from '../shared/types'
import { formatValue } from '../shared/utils'
import Tab from './components/ActivityTab'
import { ConversationsCount } from './components/ConversationsCount'
import { ConversationsLength } from './components/ConversationsLength'

const NoContent: React.FC = () => (
  <ContentPlaceholder.Container mt={20}>
    <ContentPlaceholder.Image as={Icon_EmptyState} />
    <ContentPlaceholder.Heading>
      <Trans>No results</Trans>
    </ContentPlaceholder.Heading>
    <ContentPlaceholder.Body>
      <Trans>
        Try extending the period in the top right corner or make your filtration
        wider
      </Trans>
    </ContentPlaceholder.Body>
  </ContentPlaceholder.Container>
)

const TabPanelsContent: React.FC<{
  summarySegments: Segment<ActivitySummaryResponseModel>[]
  isLoading: boolean
}> = ({ summarySegments, isLoading }) => {
  const user = useCurrentUser()
  const { getUsersByTeamUid } = useUsers()

  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useConversationsDrawer()
  const { periodDef } = useFilterPeriodContext()

  const handleOpenConversationDrawerForUserUids = React.useCallback(
    (userUids: string[], segmentIndex: number, dataTabName: string): void => {
      logEvent(Event.DataGrid_ViewUserConversations, { dataTabName })
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...states[segmentIndex].values,
              userUids: userUids,
            },
            periodDef,
          ),
      })
    },
    [openConversationsDrawer, periodDef, states],
  )

  const handleOpenConversationsDrawer = React.useCallback(
    (
      row: UserSegments | TeamSegments,
      segmentIndex: number,
      dataTabName: string,
    ): void => {
      if ('user' in row) {
        handleOpenConversationDrawerForUserUids(
          [row.user.uid],
          segmentIndex,
          dataTabName,
        )
      } else {
        const users = getUsersByTeamUid(row.team.uid)
        if (users.length > 0) {
          handleOpenConversationDrawerForUserUids(
            users.map((u) => u.uid),
            segmentIndex,
            dataTabName,
          )
        }
      }
    },
    [getUsersByTeamUid, handleOpenConversationDrawerForUserUids],
  )

  const isEmpty = !(
    isLoading ||
    summarySegments.some(
      (segment) => segment.data && segment.data.conversations !== 0,
    )
  )

  if (isEmpty) return <NoContent />

  return (
    <>
      {user.isTeamLead && !summarySegments[0].data ? (
        <TeamFilterRequiredMessage />
      ) : (
        <TabPanels flex="1">
          <TabPanel>
            <ConversationsCount
              summaries={summarySegments}
              onPlaySnippets={(row, segment) =>
                handleOpenConversationsDrawer(
                  row,
                  segment,
                  'conversations-count',
                )
              }
            />
          </TabPanel>
          <TabPanel>
            <ConversationsLength
              summaries={summarySegments}
              onPlaySnippets={(row, segment) =>
                handleOpenConversationsDrawer(
                  row,
                  segment,
                  'conversations-length',
                )
              }
            />
          </TabPanel>
        </TabPanels>
      )}
    </>
  )
}

const DataTabs: React.FC = () => {
  const {
    segments: summarySegments,
    isLoading,
    error: segmentError,
  } = useFetchSegments<ActivitySummaryResponseModel>(
    insightsAPI.getActivitySummaries,
  )

  const currentUser = useCurrentUser()
  const { getFilterRequestModel } = useFirstPhoneSegmentState()

  const filter = getFilterRequestModel()

  if (
    currentUser.isTeamLead &&
    !filter.teamUids &&
    segmentError?.statusCode === 403
  )
    return <TeamFilterRequiredMessage />

  if (segmentError && segmentError?.statusCode === 403)
    return <NoAccessibleTrackers />

  if (segmentError) return <DefaultFallbackComponent error={segmentError} />

  return (
    <Tabs my={6} isFitted isLazy>
      <TabList flexWrap="wrap" alignItems="stretch">
        <Tab
          isLoading={isLoading}
          label={t`No. conversations`}
          values={summarySegments.map((s) => ({
            color: s.color,
            label: s.label,
            data: s.data
              ? formatValue(s.data.conversations, { maximumFractionDigits: 0 })
              : 0,
          }))}
          onClick={() =>
            logEvent(Event.DataTab_Clicked, { name: 'conversations-count' })
          }
        />
        <Tab
          isLoading={isLoading}
          label={t`Duration (avg)`}
          values={summarySegments.map((s) => ({
            color: s.color,
            label: s.label,
            data: formatSeconds(s.data?.durationAverage),
          }))}
          onClick={() =>
            logEvent(Event.DataTab_Clicked, { name: 'conversations-length' })
          }
        />
      </TabList>

      <TabPanelsContent
        isLoading={isLoading}
        summarySegments={summarySegments}
      />
    </Tabs>
  )
}

export default DataTabs
